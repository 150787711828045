import { logEvent } from "firebase/analytics";
import { analytics } from "./firebaseConfig";

export const ANALYTICS_EVENTS = {
  PAGE_VIEW: "page_view",
  SCROLL_TO: "scroll_to",
  CHAT_BOTPRESS_WIDGET_CLICK: "chat_botpress_widget_click",
  CONTACT_DATA_SENT: "contact_data_sent",
};

interface EventParams {
  [key: string]: string | number | boolean;
}

export const setPageView = (pageName: string) => {
  try {
    logEvent(analytics, ANALYTICS_EVENTS.PAGE_VIEW, { page: pageName });
  } catch (e) {
    console.error(e);
  }
};

export const trackEvent = (eventName: string, eventParams?: EventParams) => {
  try {
    logEvent(analytics, eventName, eventParams);
  } catch (e) {
    console.error(e);
  }
};
