import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";

import Router from "./router";
import i18n from "./translation";
import { useEffect } from "react";
import {
  ANALYTICS_EVENTS,
  setPageView,
  trackEvent,
} from "./utils/analyticsMethods";

function addTracking() {
  const button = document.querySelector(".bpw-widget-btn");
  if (button) {
    button.addEventListener("click", () => {
      trackEvent(ANALYTICS_EVENTS.CHAT_BOTPRESS_WIDGET_CLICK);
    });
  }
}

const App = () => {
  useEffect(() => {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          addTracking();
        }
      }
    });

    // Start observing the document with the configured parameters
    observer.observe(document.body, { childList: true, subtree: true });

    setPageView("Home");
    return () => observer.disconnect(); // Cleanup observer on component unmount
  }, []);

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
